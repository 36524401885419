import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BossProfilesDataService } from '../services/Profile/boss-profiles-data.service';
import { Table } from 'primeng/table';
import { BossDataService } from '../services/Absence/boss-data.service';

@Component({
  selector: 'app-boss-employees',
  templateUrl: './boss-employees.component.html',
  styleUrls: ['./boss-employees.component.css'],
  providers: [MessageService]
})
export class BossEmployeesComponent {
  birthdayImage = 'assets/icons/birthday.png';

  isLoading: boolean = true;
  subordinates: any[] = [];
  absences:any[]=[];
  visibleDetail: boolean = false;
  filter_text:string ="";

  placeholder_imss: string='';
  userImg='assets/imgs/userImage.png';
  mimeType: string = '';
  username: string='';
  name: string='';
  phonenumber: string='';
  birthday: string='';
  escolarity: string='';
  curp: string='';
  rfc: string='';

  position: string='';
  branch: string='';
  area: string='';
  enterprise: string='';
  secomail: string='';
  boss: string='';
  vac_days: string='';
  imss: string='';
  imss_doc:string='';
  imss_type:string='';

  start_date:string='';
  seniority: string='';
  end_date: string='';
  rem_days:string='';
  period='';
  status: string='';

  constructor(
    private messageService: MessageService,
    private profilesBossService: BossProfilesDataService,
    private absencesBoss: BossDataService
    ){
  }

  ngOnInit() {
    this.getProfilesBossData()
  }
  getColors(status: string) {
    switch (status) {
        case 'Activo':
            return 'inProcess';
        case 'De vacaciones':
            return 'finished';
        case 'Dado de baja':
            return 'denied';
        case 'En periodo de prueba':
            return 'acepted';
        case 'En permiso':
            return 'waiting';
        case 'Incapacitado':
            return 'canceled';
        default:
          return 'inProcess';
    }
}
showDetail(subordinate: any) {
  this.visibleDetail = true;
  this.getProfileData(subordinate.employee_number)
  this.getAbsencesBossData(subordinate.employee_number)

}
clear(table: Table) {
  table.clear();
}
getProfilesBossData()
  {
    this.profilesBossService.getProfilesBossData().subscribe({
      next: data => {
        this.subordinates=data;
        this.isLoading=false
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener los perfiles' });
      }
  });
  }

  getProfileData(pk: string)
  {
    this.profilesBossService.getProfileDetailData(pk).subscribe({
      next: data => {
        this.username = data.employee_number;
        this.name = data.name;
        this.phonenumber= data.phonenumber;
        if (data.profile_image != ""){

          this.userImg='data:image/png;base64,' + data.profile_image;
        }
        this.birthday = data.birthday;
        this.escolarity=data.escolarity;
        this.curp = data.CURP;
        this.rfc=data.RFC;
        this.position=data.position;
        this.branch=data.branch;
        this.area=data.area;
        this.enterprise=data.enterprise;
        this.secomail=data.secomail;
        this.boss=data.boss;
        this.vac_days=data.vacations_days;
        this.imss=data.IMSS;
        this.placeholder_imss='Documento del IMSS'

        this.imss_type=data.IMSS_doc_type;
        this.imss_doc=data.IMSS_doc;
        this.start_date=data.start_date;
        this.start_date=data.start_date;
        if (data.seniority==1)
        {
          this.seniority=data.seniority+' año';
        }
        else
        {
          this.seniority=data.seniority+' años';
        }
        this.period=data.period;
        this.end_date=data.end_date;
        this.rem_days=data.rem_days;
        this.status=data.status;
        this.isLoading = false;
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener el perfil' });
      }
  });
  }
  getAbsencesBossData(pk:string)
  {
    this.absencesBoss.getAbsencesEmployeeData(pk).subscribe({
      next: data => {
        this.absences=data;
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener las ausencias.' });
      }
  });
  }
  cleanDetailForm()
  {
    this.username = '';
        this.name = '';
        this.phonenumber='';

        this.userImg='assets/imgs/userImage.png';

        this.birthday = '';
        this.escolarity='';
        this.curp = '';
        this.rfc='';
        this.position='';
        this.branch='';
        this.area='';
        this.enterprise='';
        this.secomail='';
        this.boss='';
        this.vac_days='';
        this.imss='';
        this.placeholder_imss=''

        this.imss_type='';
        this.imss_doc='';
        this.start_date='';
        this.seniority='';
        this.end_date='';
        this.rem_days='';
        this.period='';
        this.status='';
  }
  isBirthday(textDate: String): boolean {
    const [day, month, year] = textDate.split('-');
    const birthday = new Date(+year, +month - 1, +day);
    const today = new Date();
    return birthday.getMonth() === today.getMonth() && birthday.getDate() === today.getDate();

  }
  getColorsAbsence(status: string) {
    switch (status) {
        case 'Aceptada':
            return 'accepted';
        case 'Aceptado':
            return 'accepted';
        case 'En proceso':
            return 'inProcess';
        case 'Rechazada':
            return 'denied';
        case 'Rechazado':
            return 'denied';
        case 'Terminada':
            return 'finished';
        case 'Terminado':
            return 'finished'
        case 'En espera':
            return 'waiting';
        case 'Cancelada':
            return 'canceled';
        case 'No cumplio':
          return 'canceled'
        case 'En revisión':
          return 'waiting';
        case 'Entregada':
          return 'finished';
        default:
          return 'inProcess';
    }
}
  downloadFile() {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(this.base64ToBlob(this.imss_doc));
    element.download = 'Documento del IMSS de ' + this.username;
    element.click();
  }



  base64ToBlob(base64Data: string): Blob {
    const byteString = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }const base64String = '...'; // Tu cadena base64 aquí
    this.mimeType = 'application/pdf';
    var mimeTypes = require('mime')
    const mimeType = mimeTypes.getType(this.imss_type);
    if (mimeType!=null){
      this.mimeType = mimeType;
    }


    const blob = new Blob([uintArray], { type: this.mimeType });
    return blob;
  }





}
