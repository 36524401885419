import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MyNotificationsService } from '../services/Notification/my-notifications.service';

export const rhGuard = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

  const router = inject(Router);
  var result=await getRole();

if (result){
  return true;
}
else
{
  router.navigate(['']);
  return false;
}

};
async function getRole() {
  const authService =inject(MyNotificationsService);
  return new Promise((resolve, reject) => {
    authService.getNotificationsStatus().subscribe({
      next: data => {
        resolve(data.user_type=='Recursos Humanos' || data.user_type=='Jefe de Recursos Humanos');

      },
      error: (error: Error) => {
        resolve(false);
      }
    });
  });
}
