import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';

export const authGuard = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

  const router = inject(Router);
  var result=await getAuthentication();

if (result){
  return true;
}
else
{
  router.navigate(['']);
  return false;
}

};
async function getAuthentication() {
  const authService =inject(LoginService);
  return new Promise((resolve, reject) => {
    authService.checkStatus().subscribe({
      next: data => {
        resolve(data.user_type!='' && data.user_type!='Dado de baja');
      },
      error: (error: Error) => {
        resolve(false);
      }
    });
  });
}
