import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BossVacationsService } from '../services/Vacation/boss-vacations.service';
import { Table } from 'primeng/table';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-aprove-vacations',
  templateUrl: './aprove-vacations.component.html',
  styleUrls: ['./aprove-vacations.component.css'],
  providers: [MessageService]
})
export class AproveVacationsComponent {
  isLoading: boolean = true;
  isBlocked: boolean = true;

  detailId: number =0;
  detailEmployee: string ="";
  detailStart: Date=new Date();
  detailEnd: Date =new Date();
  detailSubstitute:string ="";
  detailComments:string ="";
  detailBossComments:string ="";
  detailStatus:string="";
  detailSatLeft:number=0;
  detailDaysLeft:number=0;
  detailPeriod:string="";
  detailWorkDays:number=0;
  detailSaturdays:number=0;


  vacations: any[] = [];
  visibleDetail: boolean = false;
  filter_text:string ="";
  changes:boolean=false;

  years:number[]=[]
  year:number=new Date().getFullYear();

  reportDialog:boolean=false;
  reportStartDate:Date=new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  reportEndDate:Date=new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);
  status_options:any[]=["En espera","Aceptada","Rechazada","En proceso","Terminada","Cancelada"];
  reportStatus:string[]=[];
  reportName:string="";

  constructor(
    private messageService: MessageService,
    private vacationBossService: BossVacationsService
    ){
  }
  ngOnInit() {
    const currentYear = new Date().getFullYear();
    const numberOfYearsToShow = currentYear - 2023+2;
    this.getVacationsBossData(this.year)
    this.years = Array.from({ length: numberOfYearsToShow }, (_, index) => 2023 + index);

  this.checkChanges()

  }
  showReportDialog()
{
  this.reportDialog=true;
}

  getColors(status: string) {
    switch (status) {
        case 'Aceptada':
            return 'accepted';
        case 'En proceso':
            return 'inProcess';
        case 'Rechazada':
            return 'denied';
        case 'Terminada':
            return 'finished';
        case 'En espera':
            return 'waiting';
        case 'Cancelada':
            return 'canceled';
        default:
          return 'inProcess';
    }
}
showDetail(vacation: any) {
  this.visibleDetail = true;
  this.getVacationDetail(vacation.id)

}
clear(table: Table) {
  table.clear();
}

getVacationsBossData(year: number)
  {
    this.vacationBossService.getVacationsBossData(year).subscribe({
      next: data => {
        this.vacations=data;
        this.isLoading=false;
        this.year=year
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener las vacaciones' });
      }
  });
  }
  checkChanges()
  {
    this.vacationBossService.checkNewChanges().subscribe({
      next: data => {
        if (data.newChange=='Yes')
        {
          this.changes=true
          console.log(data.newChange)
        }

      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener las vacaciones' });
      }
  });
  }
  checkChangeTextStyle()
  {

    if (this.changes)
    {
      return 'newChanges'
    }
    else
    {
      return ''
    }
  }
  checkChangeBtnStyle()
  {

    if (this.changes)
    {
      return 'newChangesBtn'
    }
    else
    {
      return 'btn'
    }
  }
  acceptVacationData()
  {
   const myVacationObject = {
      id:this.detailId,
      status:"Aceptada",
      boss_comments:this.detailBossComments


    };
    this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.vacationBossService.updateVacationStatus(this.detailId,myVacationObject).subscribe({
      next: data => {
        console.log(data)
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleDetail=false


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }
  denyVacationData()
  {
   const myVacationObject = {
      id:this.detailId,
      status:"Rechazada",
      boss_comments:this.detailBossComments

    };
    this.messageService.add({ severity: 'info', summary: 'Solicitud enviada', detail: 'Esperando respuesta del servidor' });
    this.vacationBossService.updateVacationStatus(this.detailId,myVacationObject).subscribe({
      next: data => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message });
      this.ngOnInit()
      this.visibleDetail=false


    },
    error: (error: HttpErrorResponse) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.detail});

    }
  });

  }
  async getVacationDetail(pk:number)
  {
    this.vacationBossService.getVacationDetail(pk).subscribe({
      next: data => {
        var dateParts = data.start_date.split('-');
        this.detailStart=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        dateParts = data.end_date.split('-');
        this.detailEnd=new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        this.detailId=data.id
        this.detailEmployee=data.employee
        this.detailSubstitute=data.substitute
        this.detailComments=data.comments
        this.detailBossComments=data.boss_comments
        this.detailStatus=data.status
        this.detailSatLeft=data.saturdays_left
        this.detailDaysLeft=data.days_left
        this.detailPeriod=data.period
        this.detailWorkDays=data.work_days
        this.detailSaturdays=data.saturdays
        if (this.detailStatus!="En espera"){
          this.isBlocked=false
        }
        else
        {
          this.isBlocked=true
        }



      },
      error: (error: Error) => {
      }
  });
  }





  getReport()
  {
    const convStart=this.reportStartDate.getFullYear()+"-"+(this.reportStartDate.getMonth()+1)+"-"+this.reportStartDate.getDate()
    const convEnd=this.reportEndDate.getFullYear()+"-"+(this.reportEndDate.getMonth()+1)+"-"+this.reportEndDate.getDate()
    this.vacationBossService.getVacationsReport(convStart,convEnd,this.reportStatus,this.reportName).subscribe({
      next: data => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        const textStart = this.reportStartDate.getDate().toString().padStart(2, '0') + "-" +
                  (this.reportStartDate.getMonth() + 1).toString().padStart(2, '0') + "-" +
                  this.reportStartDate.getFullYear();
        const textEnd = this.reportEndDate.getDate().toString().padStart(2, '0') + "-" +
                        (this.reportEndDate.getMonth() + 1).toString().padStart(2, '0') + "-" +
                        this.reportEndDate.getFullYear();
        const file_name="Reporte de vacaciones del " + textStart + " al " + textEnd + ".pdf"
        link.download = file_name;
        link.click()

        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Archivo generado.' });
      },
      error: (error: Error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener el archivo.' });
      }
    });
  }

  cleanReportForm()
  {
    this.reportStartDate=new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.reportEndDate=new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);
    this.reportStatus=[];
    this.reportName="";

  }


}
