<app-home></app-home>
<p-toast></p-toast>
<ng-container *ngIf="!isLoading">
<div class="grid">

  <div class="col-10" style="margin:auto;">
      <h2 class="titulo">Consulta de colaboradores.</h2>
      <h4>*Selecciona el empleado del que quieras conocer mas información.</h4>
      <p-table #dt1 [value]="subordinates" [tableStyle]="{'min-width': '40rem'}"  selectionMode="single"   [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 25]"
      [globalFilterFields]="['name','position', 'area', 'enterprise', 'branch', 'status']">
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col-6">
          <button pButton label="Limpiar filtros" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
        </div>
        <div class="col-6" style="align-items:end;display: flex;">
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [(ngModel)]="filter_text" (input)="dt1.filterGlobal(filter_text, 'contains')" placeholder="Buscar" />
            </span>
          </div>
        </div>
    </ng-template>
      <ng-template pTemplate="header">
              <tr>
                  <th>Numero de empleado</th>
                  <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                  <th pSortableColumn="position">Posición <p-sortIcon field="position"></p-sortIcon></th>
                  <th pSortableColumn="area">Area <p-sortIcon field="area"></p-sortIcon></th>
                  <th pSortableColumn="enterprise">Empresa <p-sortIcon field="enterprise"></p-sortIcon></th>
                  <th pSortableColumn="branch">Sucursal <p-sortIcon field="branch"></p-sortIcon></th>
                  <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-subordinate >
              <tr (click)="showDetail(subordinate)">
                  <td>{{ subordinate.employee_number }}</td>
                  <td>{{subordinate.name}}<img  *ngIf="isBirthday(subordinate.birthday)" [src]="birthdayImage" style="width: 16px; height: 16px; margin-left: 5px;"></td>
                  <td>{{ subordinate.position }}</td>
                  <td>{{ subordinate.area }}</td>
                  <td>{{ subordinate.enterprise }}</td>
                  <td>{{subordinate.branch}}</td>
                  <td><tag  [ngClass]="getColors(subordinate.status)">{{subordinate.status}}</tag></td>
              </tr>
          </ng-template>


      </p-table>

</div>
</div>
</ng-container>


<p-dialog [header]="'Perfil del colaborador:'" [(visible)]="visibleDetail" [modal]="true" [draggable]="false" (onHide)="cleanDetailForm()" *ngIf="status!=''" style="width:100% !important">
  <div class="grid" style="min-width: 80%;">
    <div class="col-10 md:col-3 lg:col-3" style="margin:auto;margin-top: 1%;">
      <h3 class="title">Informacion del empleado.</h3>
      <div class="frame" style="display: flex; align-items: center; justify-content: center;">

        <img [src]="userImg">
      </div>

        <div class="field">
                <div class="flex flex-column gap-2">
                  <label htmlFor="username">Numero de empleado</label>
                  <input type="text" pInputText [(ngModel)]="username" name="username" placeholder="Numero de empleado" disabled>
                </div>
        </div>

            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="nombre">Nombre completo</label>
                <input type="text" pInputText [(ngModel)]="name" name="nombre" placeholder="Nombre" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="telefono">Telefono</label>
                <input type="text" pInputText [(ngModel)]="phonenumber" name="telefono" placeholder="Telefono" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Fecha de nacimiento</label>
                <input type="text" pInputText [(ngModel)]="birthday" name="fecNacimiento" placeholder="Fecha de nacimiento" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Escolaridad</label>
                <input type="text" pInputText [(ngModel)]="escolarity" name="escolaridad" placeholder="Escolaridad" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">CURP</label>
                <input type="text" pInputText [(ngModel)]="curp" name="curp" placeholder="CURP" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">RFC</label>
                <input type="text" pInputText [(ngModel)]="rfc" name="rfc" placeholder="RFC" disabled>
              </div>
            </div>
    </div>



    <div class="col-10 md:col-3 lg:col-3" style="margin:auto;margin-top: 1%;">
      <h3 class="title">Informacion en la empresa.</h3>

        <div class="field">
                <div class="flex flex-column gap-2">
                  <label htmlFor="username">Puesto</label>
                  <input type="text" pInputText [(ngModel)]="position" name="position" placeholder="Puesto" disabled>
                </div>
        </div>

            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="nombre">Sucursal</label>
                <input type="text" pInputText [(ngModel)]="branch" name="brach" placeholder="Sucursal" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="telefono">Area</label>
                <input type="text" pInputText [(ngModel)]="area" name="area" placeholder="Area" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Empresa</label>
                <input type="text" pInputText [(ngModel)]="enterprise" name="enterprise" placeholder="Empresa" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Correo de Secovam</label>
                <input type="text" pInputText [(ngModel)]="secomail" name="mail" placeholder="Correo de Secovam" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Jefe inmediato</label>
                <input type="text" pInputText [(ngModel)]="boss" name="boss" placeholder="Jefe Inmediato" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Dias de vacaciones al año</label>
                <input type="text" pInputText [(ngModel)]="vac_days" name="vac_days" placeholder="Dias de vacaciones al año" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">IMSS</label>
                <input type="text" pInputText [(ngModel)]="imss" name="imss" placeholder="imss" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2"  (click)="downloadFile()" style=" cursor: pointer;">

                <label htmlFor="username">Alta del IMSS</label>
                <input type="text" pInputText [(ngModel)]="placeholder_imss" name="imss_doc"  placeholder="Alta del IMSS" disabled style=" cursor: pointer; text-decoration: underline;">


              </div>
            </div>

    </div>


    <div class="col-10 md:col-3 lg:col-3" style="margin:auto;margin-top: 1%;">
      <h3 class="title">Estado del empleado.</h3>

        <div class="field">
                <div class="flex flex-column gap-2">
                  <label htmlFor="username">Fecha de ingreso</label>
                  <input type="text" pInputText [(ngModel)]="start_date" name="start_date" placeholder="Fecha de ingreso" disabled>
                </div>
        </div>

            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="nombre">Antigüedad</label>
                <input type="text" pInputText [(ngModel)]="seniority" name="seniority" placeholder="Antigüedad" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2" *ngIf="end_date!==null">
                <label htmlFor="telefono">Fecha de salida</label>
                <input type="text" pInputText [(ngModel)]="end_date" name="end_date" placeholder="Fecha de salida" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Dias restantes de vacaciones</label>
                <input type="text" pInputText [(ngModel)]="rem_days" name="rem_days" placeholder="Dias restantes de vacaciones" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="fecNacimiento">Periodo vacacional actual</label>
                <input type="text" pInputText [(ngModel)]="period" name="period" placeholder="Periodo vacacional" disabled>
              </div>
            </div>
            <div class="field">
              <div class="flex flex-column gap-2">
                <label htmlFor="username">Status</label>
                <input type="text" pInputText [(ngModel)]="status" [ngClass]="getColors(status)" name="status" placeholder="Status" disabled >
              </div>
            </div>

    </div>
    <div class="col-10 md:col-10 lg:col-10" style="margin:auto;margin-top: 1%;">
    <p-table #dt1 [value]="absences" [tableStyle]="{'min-width': '40rem'}"  selectionMode="single"   [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 25]"
        [globalFilterFields]="['start_date','employee', 'end_date', 'type', 'status']">
        <ng-template pTemplate="caption">
          <div class="grid">
            <div class="col-6">
            <button pButton label="Limpiar filtros" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
          </div>
          <div class="col-6" style="align-items:end;display: flex;">
              <span class="p-input-icon-left ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" [(ngModel)]="filter_text" (input)="dt1.filterGlobal(filter_text, 'contains')" placeholder="Buscar" />
              </span>
            </div>
          </div>
      </ng-template>
        <ng-template pTemplate="header">
                <tr>
                    <th>Id</th>
                    <th pSortableColumn="start_date">Fecha de inicio <p-sortIcon field="start_date"></p-sortIcon></th>
                    <th pSortableColumn="end_date">Fecha de fin <p-sortIcon field="end_date"></p-sortIcon></th>
                    <th pSortableColumn="type">Tipo <p-sortIcon field="type"></p-sortIcon></th>
                    <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-absence >
                <tr (click)="absence">
                    <td>{{ absence.id }}</td>
                    <td>{{ absence.start_date }}</td>
                    <td>{{ absence.end_date }}</td>
                    <td>{{ absence.type }}</td>
                    <td><tag  [ngClass]="getColorsAbsence(absence.status)">{{absence.status}}</tag></td>
                </tr>
            </ng-template>


        </p-table>
        </div>


    </div>

</p-dialog>







<ng-container *ngIf="isLoading">
  <div class="loading-overlay">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</ng-container>
