<div class="main-content">
  <router-outlet></router-outlet>

</div>
<footer class="footer" *ngIf="notLogin" style="background-color: #353334; color:#9DA1A6; min-height: 5vh; bottom: 0;width: 100%;">
  <div class="grid" style="height: 100%; margin:0px">
    <div class="col-1"></div>
    <div class="col-10" style="display: flex;align-items: center;justify-content: center;margin:auto; ">© Derechos reservados SECOVAM&nbsp;|
      &nbsp; <a href="https://secovam.com/aviso-de-privacidad/" target="_blank">AVISO DE PRIVACIDAD</a>
       </div>
    <div class="col-1"> </div>
  </div>
</footer>
