<app-home></app-home>
<p-toast></p-toast>
<ng-container *ngIf="!isLoading">
  <div class="grid">
    <div class="col-10" style="margin: auto">
      <h2 class="titulo">Gestion de ausencias.</h2>
      <div class="grid">
        <div class="col-4 md:col-2 lg:col-2" *ngFor="let year of years">
          <button class="btn" (click)="getAbsencesBossData(year)">
            {{ year }}
          </button>
        </div>
      </div>
      <h4>*Selecciona la solicitud con la que deseas interactuar.</h4>
      <p-table
        #dt1
        [value]="absences"
        [tableStyle]="{ 'min-width': '40rem' }"
        selectionMode="single"
        [paginator]="true"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="[
          'start_date',
          'employee',
          'end_date',
          'type',
          'status'
        ]"
      >
        <ng-template pTemplate="caption">
          <div class="grid">
            <div class="col-6">
              <button
                pButton
                label="Limpiar filtros"
                class="p-button-outlined"
                icon="pi pi-filter-slash"
                (click)="clear(dt1)"
              ></button>
            </div>
            <div class="col-6" style="align-items: end; display: flex">
              <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="filter_text"
                  (input)="dt1.filterGlobal(filter_text, 'contains')"
                  placeholder="Buscar"
                />
              </span>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Id</th>
            <th pSortableColumn="employee">
              Empleado <p-sortIcon field="Employee"></p-sortIcon>
            </th>
            <th pSortableColumn="start_date">
              Fecha de inicio <p-sortIcon field="start_date"></p-sortIcon>
            </th>
            <th pSortableColumn="end_date">
              Fecha de fin <p-sortIcon field="end_date"></p-sortIcon>
            </th>
            <th pSortableColumn="type">
              Tipo <p-sortIcon field="type"></p-sortIcon>
            </th>
            <th pSortableColumn="status">
              Status <p-sortIcon field="status"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-absence>
          <tr (click)="showDetail(absence)">
            <td>{{ absence.id }}</td>
            <td>{{ absence.employee }}</td>
            <td>{{ absence.start_date }}</td>
            <td>{{ absence.end_date }}</td>
            <td>{{ absence.type }}</td>
            <td style="width: 20%">
              <tag [ngClass]="getColors(absence.status)">{{
                absence.status
              }}</tag>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="grid">
        <div class="col-8">
          <h3>Generar reportes:</h3>
        </div>
        <div class="col-4" style="align-items: center; display: flex">
          <button (click)="showReportDialog()" class="btn" type="submit">
            Imprimir
          </button>
        </div>
      </div>
    </div>
  </div>

  <p-dialog
    header=""
    [(visible)]="visibleDetail"
    [modal]="true"
    [draggable]="false"
    (onHide)="cleanDetailForm()"
    *ngIf="detailStatus != ''"
  >
    <ng-template pTemplate="header">
      <div *ngIf="detailType == 'Vacación'">
        <span class="text-xl font-bold"
          >Resumen de la solicitud de vacaciones: {{ this.detailId }} de
          {{ this.detailEmployee }} <i class="pi pi-sun"></i
        ></span>
      </div>
      <div *ngIf="detailType == 'Permiso'">
        <span class="text-xl font-bold"
          >Resumen del permiso: {{ this.detailId }} de
          {{ this.detailEmployee }} <i class="pi pi-calendar"></i
        ></span>
      </div>
      <div *ngIf="detailType == 'Incapacidad'">
        <span class="text-xl font-bold"
          >Resumen de la incapacidad: {{ this.detailId }} de
          {{ this.detailEmployee }} <i class="pi pi-user-plus"></i
        ></span>
      </div>
    </ng-template>
    <div *ngIf="detailType == 'Vacación'">
      <div class="container" style="min-width: 30vw">
        <div class="content-input">
          <label htmlFor="detail-start">Fecha de inicio</label>
          <p-calendar
            [(ngModel)]="detailStart"
            name="detail-start"
            dateFormat="dd-mm-yy"
            [showIcon]="true"
            [disabled]="true"
            appendTo="body"
            class="select-dates"
            [disabled]="true"
          ></p-calendar>
        </div>
      </div>
      <div class="container">
        <div class="content-input">
          <label htmlFor="detail-end">Fecha de fin</label>
          <p-calendar
            [(ngModel)]="detailEnd"
            name="detail-end"
            dateFormat="dd-mm-yy"
            [showIcon]="true"
            [disabled]="true"
            appendTo="body"
            class="select-dates"
            [disabled]="true"
          ></p-calendar>
        </div>
      </div>
      <div class="container">
        <div class="content-input">
          <label htmlFor="detail-substitute">Sustituto</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="detailSubstitute"
            name="detail-substitute"
            [disabled]="true"
            placeholder="Sustituto"
            [disabled]="true"
          />
        </div>
      </div>
      <div class="container">
        <div class="content-input">
          <label htmlFor="detail-status">Status</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="detailStatus"
            name="detail-status"
            placeholder="Status"
            [disabled]="true"
            [ngClass]="getColors(detailStatus)"
          />
        </div>
      </div>
      <div class="container">
        <div class="content-input">
          <label htmlFor="detail-comment">Comentarios</label>
          <textarea
            type="text"
            pInputText
            [(ngModel)]="detailComments"
            name="detail-comment"
            [disabled]="true"
            placeholder="Comentarios"
            [disabled]="true"
          ></textarea>
        </div>
      </div>
      <div class="container">
        <div class="content-input">
          <label htmlFor="detail-boss">Comentarios del jefe</label>
          <textarea
            type="text"
            pInputText
            [(ngModel)]="detailBossComments"
            name="detail-boss"
            placeholder="Comentarios del jefe"
            style="font-weight: bold"
          ></textarea>
        </div>
      </div>
    </div>
    <div *ngIf="detailType == 'Permiso'">
      <div class="container">
        <div class="content-input">
          <label for="detail-motive">Motivo</label>
          <p-dropdown
            [options]="motives"
            appendTo="body"
            name="detail-motive"
            [(ngModel)]="detailMotive"
            placeholder="Seleccionar motivo"
            [disabled]="true"
          ></p-dropdown>
        </div>
      </div>
      <div *ngIf="detailMotive == 'Otro'">
        <span>*Este permiso es sin gose de sueldo</span>
      </div>
      <div *ngIf="detailMotive != ''">
        <div class="container">
          <div class="content-input">
            <label for="detail-start">Fecha de inicio</label>
            <p-calendar
              [(ngModel)]="detailStart"
              name="detail-start"
              dateFormat="dd-mm-yy"
              [showIcon]="true"
              appendTo="body"
              class="select-dates"
              [disabled]="true"
            ></p-calendar>
          </div>
        </div>
      </div>
      <div *ngIf="detailMotive != ''">
        <div class="container">
          <div class="content-input">
            <label for="detail-end">Fecha de fin</label>
            <p-calendar
              [(ngModel)]="detailEnd"
              name="detail-end"
              dateFormat="dd-mm-yy"
              [showIcon]="true"
              appendTo="body"
              class="select-dates"
              [disabled]="true"
            ></p-calendar>
          </div>
        </div>
      </div>
      <div class="container" *ngIf="detailRelative != ''">
        <div class="content-input">
          <label for="detail-relative">Familiar</label>
          <p-dropdown
            [options]="relatives"
            [(ngModel)]="detailRelative"
            name="detail-relative"
            [disabled]="true"
          ></p-dropdown>
        </div>
      </div>
      <div class="container" *ngIf="base64DetailBeforeProof != ''">
        <div class="content-input">
          <label for="detail-proof">Comprobante:</label>
          <button class="btn" (click)="downloadFile()">
            <i class="pi pi-download"></i>
          </button>
        </div>
      </div>

      <div class="container" *ngIf="detailOtherType != ''">
        <div class="content-input">
          <label for="detail-other-type">Tipo de permiso</label>
          <p-dropdown
            [options]="otherTypes"
            [(ngModel)]="detailOtherType"
            name="detail-other-type"
            placeholder="Seleccionar tipo"
            [disabled]="true"
          ></p-dropdown>
        </div>
      </div>
      <div class="container" *ngIf="detailOtherType == 'Horas'">
        <div class="content-input">
          <label for="detail-time">Horario de ausencia</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="detailTime"
            name="detail-time"
            placeholder="Horario de ausencia"
            [disabled]="true"
          />
          <span
            >*El formato de hora utilizado es de 24 horas. Ejemplo:
            12:00-16:00</span
          >
        </div>
      </div>
      <div
        class="container"
        *ngIf="detailRecoveryDay != [] && recoveryDetailDecision == true"
      >
        <div class="content-input">
          <label for="selected-recovery-day">Fecha(s) de recuperación</label>
          <p-calendar
            [(ngModel)]="detailRecoveryDay"
            name="selected-recovery-day"
            dateFormat="dd-mm-yy"
            [showIcon]="true"
            appendTo="body"
            class="select-dates"
            selectionMode="multiple"
            [disabled]="true"
          >
          </p-calendar>
        </div>
      </div>
      <div
        class="container"
        *ngIf="detailRecoveryDay != [] && recoveryDetailDecision == true"
      >
        <div class="content-input">
          <label for="detail-recovery-time">Horario(s) de recuperación</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="detailRecoveryTime"
            name="detail-recovery-time"
            placeholder="Horarios de recuperación"
            [disabled]="true"
          />
          <span
            >*En caso de ser necesario escribe los periodos de hora separados
            por coma: 8:00-10:00,12:00-14:00
          </span>
        </div>
      </div>
      <div class="container">
        <div class="content-input">
          <label for="detail-status">Status</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="detailStatus"
            name="detail-status"
            placeholder="Status de la solicitud"
            [ngClass]="getColors(detailStatus)"
            [disabled]="true"
          />
        </div>
      </div>
      <div class="container">
        <div class="content-input">
          <label for="detail-comments">Comentarios</label>
          <textarea
            type="text"
            pInputText
            [(ngModel)]="detailComments"
            name="detail-comments"
            placeholder="Comentarios"
            [disabled]="true"
          ></textarea>
        </div>
      </div>
      <div class="container">
        <div class="content-input">
          <label for="detail-boss-comments">Comentarios del jefe</label>
          <textarea
            type="text"
            pInputText
            [(ngModel)]="detailBossComments"
            name="detail-boss-comments"
            placeholder="Comentarios del jefe"
            [disabled]="isAccepted"
            style="font-weight: bold"
          ></textarea>
        </div>
      </div>
    </div>
    <div *ngIf="detailType == 'Incapacidad'">
      <div class="content-input">
        <label htmlFor="detail-start">Fecha de inicio</label>
        <p-calendar
          [(ngModel)]="detailStart"
          name="detail-start"
          dateFormat="dd-mm-yy"
          [showIcon]="true"
          appendTo="body"
          class="select-dates"
          [disabled]="true"
        ></p-calendar>
      </div>
      <div class="content-input">
        <label htmlFor="detail-end">Fecha de fin</label>
        <p-calendar
          [(ngModel)]="detailEnd"
          name="detail-end"
          dateFormat="dd-mm-yy"
          [showIcon]="true"
          appendTo="body"
          class="select-dates"
          [disabled]="true"
        ></p-calendar>
      </div>
      <div class="content-input">
        <label htmlFor="detail-duration">Dias de incapacidad</label>
        <input
          type="text"
          pInputText
          [(ngModel)]="detailDuration"
          name="detail-duration"
          placeholder="Dias de incapacidad(Numero)"
          [disabled]="true"
        />
      </div>
      <div class="content-input">
        <label htmlFor="detail-motive">Motivo</label>
        <input
          type="text"
          pInputText
          [(ngModel)]="detailMotive"
          name="detail-motive"
          placeholder="Motivo de incapacidad"
          [disabled]="true"
        />
      </div>
      <div class="content-input">
        <label for="detail-proof">Documento del IMSS</label>
        <button class="btn" (click)="downloadImssFile()">
          <i class="pi pi-download"></i>
        </button>
      </div>
      <div class="content-input">
        <label htmlFor="detail-status">Status</label>
        <input
          type="text"
          pInputText
          [(ngModel)]="detailStatus"
          name="detail-status"
          placeholder="Status de incapacidad"
          [ngClass]="getColors(detailStatus)"
          [disabled]="true"
        />
      </div>
      <div class="content-input">
        <label htmlFor="detail-comments">Comentarios</label>
        <textarea
          type="text"
          pInputText
          [(ngModel)]="detailComments"
          name="detail-comments"
          placeholder="Comentarios"
          [disabled]="true"
        ></textarea>
      </div>
      <div class="content-input">
        <label htmlFor="detail-boss-comments">Comentarios de RH</label>
        <textarea
          type="text"
          pInputText
          [(ngModel)]="detailBossComments"
          name="detail-boss-comments"
          placeholder="Comentarios del jefe"
          [disabled]="true"
        ></textarea>
      </div>
    </div>
  </p-dialog>

  <p-dialog
    header=""
    [(visible)]="reportDialog"
    [modal]="true"
    [draggable]="false"
    (onHide)="cleanReportForm()"
  >
    <ng-template pTemplate="header">
      <div>
        <span class="text-xl font-bold"
          >Impresion de reportes de ausencias: <i class="pi pi-file"></i
        ></span>
      </div>
    </ng-template>
    <div>
      <div class="container">
        <div class="content-input">
          <label htmlFor="detail-start">Fecha de inicio del reporte</label>
          <p-calendar
            [(ngModel)]="reportStartDate"
            name="detail-start"
            dateFormat="dd-mm-yy"
            [showIcon]="true"
            appendTo="body"
            class="select-dates"
          ></p-calendar>
        </div>
      </div>
      <div class="container">
        <div class="content-input">
          <label htmlFor="detail-start">Fecha de fin del reporte</label>
          <p-calendar
            [(ngModel)]="reportEndDate"
            name="detail-start"
            dateFormat="dd-mm-yy"
            [showIcon]="true"
            appendTo="body"
            class="select-dates"
          ></p-calendar>
        </div>
      </div>
      <div class="container" style="min-width: 30vw">
        <div class="content-input">
          <label htmlFor="detail-addDays">Status:</label><br />
          <p-multiSelect
            [options]="status_options"
            appendTo="body"
            [(ngModel)]="reportStatus"
            placeholder="Escoge los status que quieres imprimir"
          ></p-multiSelect>
        </div>
      </div>
      <div class="container" style="min-width: 30vw;">
        <div class="content-input">
          <label htmlFor="detail-addDays">Pagado:</label><br>
          <p-multiSelect [options]="['Si','No']" appendTo="body" [(ngModel)]="reportPaid" placeholder="Escoge si es o no pagado"></p-multiSelect></div></div>

      <div class="container" style="min-width: 30vw">
        <div class="content-input">
          <label htmlFor="detail-addDays">Nombre del empleado:</label><br />
          <input
            type="text"
            pInputText
            [(ngModel)]="reportName"
            name="detail-addDays"
            placeholder="Escoge el nombre de algun empleado o dejalo vacio"
            style="font-weight: normal; width: 100%"
          />
        </div>
      </div>

      <button
        type="submit"
        (click)="getReport()"
        class="btn"
        style="margin-top: 2%"
      >
      Generar.
      </button>
    </div>
  </p-dialog>

  <ng-container *ngIf="isLoading">
    <div class="loading-overlay">
      <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
    </div>
  </ng-container>
</ng-container>
